'use strict';

$.ajaxSetup({
  crossDomain: true,
  xhrFields: {
    withCredentials: true
  },
  cache: false,
  headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': '-1', 'Token': '' }
});

var defineToken = function (token) {
  $.ajaxSetup({
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    },
    cache: false,
    headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': '-1', 'Token': token }
  });
}

var reloadTableDataSetParameters;

var Functions = {
  setLoginCss: function (url, css) {
    // Verifica se o elemento já existe
    var loginStyle = document.getElementById('loginStyle');
    if (loginStyle) {
      loginStyle.setAttribute('href', url + 'assets/configurations/css/' + css);
    } else {
      document.head.innerHTML += '<link id="loginStyle" href="' + url + 'assets/configurations/css/' + css + '" rel="stylesheet">';
    }
  },
  setTemplateSettings: function (url, css, logo, logoUrl) {

    if (logoUrl) {
      document.getElementById('appLogo').setAttribute('src', logoUrl);
    } 
    else if(!document.getElementById('appLogo').src.includes(logo))
    {
      document.getElementById('appLogo').setAttribute('src', url + 'assets/configurations/logos/' + logo);
    }
    else {
      
    }

    var href = css.length > 0 ? css + '.css' : 'id4software.css';
    var colorStyle = document.getElementById('colorStyle');
    if (colorStyle !== undefined && colorStyle !== null) {
      colorStyle.setAttribute('href', url + 'assets/configurations/css/' + href);
    } else {
      document.head.innerHTML += '<link id="colorStyle" href="' + url + 'assets/configurations/css/' + href + '" rel="stylesheet">';
    }
  },
  modalEffects: function () {
    $('.md-trigger').modalEffects();
  },
  loading: function (loading) {
    document.getElementById('loader-container').style.display = loading ? 'block' : 'none';
  },
  scrollTo: function (element, extra) {
    $(element).before('<div id="scrollToDiv"></div>');

    if ($('#scrollToDiv').offset()) {
      $('html, body').animate({
        scrollTop: $('#scrollToDiv').offset().top - extra
      }, 600);
    }

    $('#scrollToDiv').remove();

    $(element).focus();
  },

  dataTimePicker: function (element, lang, type, formatData, minutesStep, show, tooltipsTrans, minDate, maxDate,
    minDays, minHour, maxHour, weekDays, orderHourLimit, podeMostrar) {

    var elementJQ = $(element);
    if (type == 1) {
      elementJQ.datetimepicker({
        format: formatData,
        locale: lang,
        tooltips: tooltipsTrans
      });
    }
    if (type == 2) {
      elementJQ.datetimepicker({
        format: 'HH:mm',
        // stepping: minutesStep,
        locale: lang,
        useCurrent: 'day',
        tooltips: tooltipsTrans
      });

      if (typeof minutesStep !== 'undefined' && elementJQ && elementJQ.data('DateTimePicker')) {
        elementJQ.data('DateTimePicker').stepping(minutesStep);
      }
    }
    if (type == 3) {
      elementJQ.datetimepicker({
        format: formatData,
        locale: lang,
        // stepping: minutesStep,
        tooltips: tooltipsTrans
      });

      if (typeof minutesStep !== 'undefined' && elementJQ && elementJQ.data('DateTimePicker')) {
        elementJQ.data('DateTimePicker').stepping(minutesStep);
      }
    }
    if (type == 4) {
      elementJQ.datetimepicker({
        format: 'YYYY-MM',
        useCurrent: true
      });
    }
    if (type == 5) {
      var monthYear = elementJQ[0].value;
      elementJQ.datetimepicker({
        useCurrent: false,
        format: 'MMMM YYYY',
        locale: lang
      });
    }

    try {
      if (minDate) {
        elementJQ.data('DateTimePicker').minDate(minDate);
      }
    } catch (ex) { };

    try {
      if (maxDate) {
        elementJQ.data('DateTimePicker').maxDate(maxDate);
      }
    } catch (ex) { };

    try {
      if (minDays && minHour && maxHour && weekDays) {

        var indexesWeekDays = [], i = -1;
        while ((i = weekDays.indexOf(false, i + 1)) != -1) {
          indexesWeekDays.push(i);
        }

        var enableHours = [];
        for (var h = minHour; h <= maxHour; h++) {
          enableHours.push(h);
        }


        var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), minHour);

        if (orderHourLimit > 0) {
          var actualHour = new Date().getHours();

          if (actualHour > orderHourLimit) {
            startDate.setDate(startDate.getDate() + (minDays + 1)); // startDate = data atual + minDays
          } else {
            startDate.setDate(startDate.getDate() + minDays); // startDate = data atual + minDays
          }

        } else {
          startDate.setDate(startDate.getDate() + minDays); // startDate = data atual + minDays
        }

        elementJQ.data('DateTimePicker').minDate(startDate); // só pode selecionar datas a partir da startDate
        elementJQ.data('DateTimePicker').enabledHours(enableHours); // só pode selecionar as horas compreendidas entre o minHour e o maxHour
        elementJQ.data('DateTimePicker').daysOfWeekDisabled(indexesWeekDays); // só pode selecionar as horas compreendidas entre o minHour e o maxHour

      }
    } catch (ex) { };


    if (!podeMostrar) {
      elementJQ.on('dp.show', function (e) {
        e.preventDefault();
      });
    }
    elementJQ.addClass('hasDatepicker'); // para saber depois se ja tem o datepicker
    if (show) {
      elementJQ.datetimepicker('show'); // obrigar a mostrar logo
    }
    elementJQ.on('dp.hide', function (event) { var input = $(event.currentTarget); if (input.val() != '') { input.find('.dataChange').click(); input.datetimepicker('hide'); } });
    elementJQ.on('dp.show', function (e) {
      // colocar no topo de todos os elementos
      var datepicker = $('body').find('.bootstrap-datetimepicker-widget:last'),
        position = datepicker.offset(),
        parent = datepicker.parent(),
        parentPos = parent.offset(),
        width = datepicker.width(),
        parentWid = parent.width(),
        left = position.left,
        top = position.top;

      if (elementJQ.hasClass('dataTable')) { // para saber se é um input dentro da datatable pois nao estava a assumir a posicao correta
        left = elementJQ.offset().left;
        top = elementJQ.offset().top - datepicker[0].getBoundingClientRect().height;
      }

      // move datepicker to the exact same place it was but attached to body
      if (datepicker && document.querySelector('mat-dialog-container')) {
        // se tiver numa modal, pode estar com scroll
        top = top - document.querySelector('body').getBoundingClientRect().top;
        left = left - document.querySelector('body').getBoundingClientRect().left;
      }
      datepicker.appendTo('body');
      datepicker.css({
        position: 'absolute',
        top: top,
        bottom: 'auto',
        left: left,
        right: 'auto'
      });

      datepicker.attr('style', datepicker.attr('style') + '; z-index: 1000000');

      // if datepicker is wider than the thing it is attached to then move it so the centers line up
      if (parentPos.left + parentWid < position.left + width) {
        var newLeft = parentPos.left;
        newLeft += parentWid / 2;
        newLeft -= width / 2;
        datepicker.css({ left: newLeft });
      }
    });
  },
  nextDate: function (minDays, minHour, maxHour, weekDays) {
    var daysToIncrement = minDays + (new Date().getHours() >= maxHour ? 1 : 0);

    for (var i = 0; i < daysToIncrement; i++) {
      var weekDay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + i).getDay();
      if (!weekDays[weekDay]) {
        daysToIncrement++;
      }
    }

    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + daysToIncrement, minHour);
  },
  tooltip: function () {
    if (jQuery().tooltip) {
      $('[data-toggle="tooltip"]').tooltip();
    }
  },
  tooltipPassword: function () {
    if (jQuery().tooltip) {
      $("[type=password]").keypress(function (e) {
        var $password = $(this);
        var tooltipVisible = $(".tooltip").is(":visible");
        var s = String.fromCharCode(e.which);

        //testar se esta letra maiuscula e se o shift nao esta pressionado
        if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
          if (!tooltipVisible) {
            $password.tooltip("show");
          }
        } else {
          if (tooltipVisible) {
            $password.tooltip("hide");
          }
        }

        //Remover o aviso se sair do campo password
        $password.blur(function (e) {
          $password.tooltip("hide");
        });
      });
    }
  },
  passwordTooltip: function () {
    $("[type=password]").keypress(function (e) {
      var $password = $(this);
      var s = String.fromCharCode(e.which);

      //testar se esta letra maiuscula e se o shift nao esta pressionado
      if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
        $("#tooltipOn").click();

      } else {
        $("#tooltipOff").click();
      }

    });
  },
  // className: success, warning ou danger
  gritter: function (message, className, popupTimeDanger = 100000) {
    $.gritter.add({
      // title: message,
      // text: message,
      text: className === 'danger' ?
        '<div><div class="circleinwhite danger gritter-image"><div class="text-center"><i class="fas fa-times"></i></div></div><div class="gritter-text">' + message + '</div></div>' :
        '<div><div class="circleinwhite ' + className + ' gritter-image"><div class="text-center"><i class="fas fa-check"></i></div></div><div class="gritter-text">' + message + '</div></div>',
      class_name: className,
      // sticky: className === 'danger' ? true : false
      time: className === 'danger' ? popupTimeDanger : className === 'warning' ? 60000 : 3000
    });
    return false;
  },
  datatablesDatetimeFormat: function (format) {
    $.fn.dataTable.moment(format);
  },
  alignColumns: function ()
  {
    $($.fn.dataTable.tables(true)).DataTable()
      .columns.adjust();
  },
  datatables: function (idTabela, dataUrl, translates, aoColumns, columnDefs, langFile, type, data, paging, globalSearch, stateSave, select, parameters, deferLoading, isObjectList = false) {
    var hasFilterButtons = (typeof parameters !== 'undefined' && typeof parameters.hasFilterButtons !== 'undefined') ? parameters.hasFilterButtons : true;
    var dom = (typeof parameters !== 'undefined') ? parameters.dom : undefined;

    // orderType = 'asc' ou 'desc'
    var paging = (typeof paging === 'undefined') ? true : paging;
    var globalSearch = (typeof globalSearch === 'undefined') ? true : globalSearch;
    var stateSave = (typeof stateSave === 'undefined') ? true : stateSave;
    var select = (typeof select === 'undefined') ? false : select;

    if (typeof dom === 'undefined') {
      dom = globalSearch
        ? 'f tr <"col-sm-3 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-5 no-padding margin-top-10"p>'
        : 'tr <"col-sm-3 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-5 no-padding margin-top-10"p>';
      //'l' - Length changing ; 'f' - Filtering input; 't' - The table; 'i' - Information; 'p' - Pagination; 'r' - pRocessing
    }

    var table = $(idTabela).DataTable({
      'pageLength': (typeof parameters !== 'undefined') ? parameters.length : 10,
      'displayStart': (typeof parameters !== 'undefined') ? parameters.start : 0,
      //'ordering': (typeof parameters !== 'undefined' && parameters.order) ? true : false,
      order: (typeof parameters !== 'undefined' && parameters.order) ? parameters.order : [[1, "asc"]],
      //order: [[ 1, "asc" ]],
      'rowGroup': (typeof parameters !== 'undefined') ? parameters.rowGroup : undefined,
      "dom": dom,
      stateSave: stateSave, //para guardar o estado, uses the HTML5 localStorage and sessionStorage
      "processing": true,
      "serverSide": true,
      "deferLoading": (typeof deferLoading !== 'undefined') ? deferLoading : null, // se null vai desenhar logo, se 0 so desenha apos ser chamado o draw, se outro vai esperar para desenhar
      "ajax": {
        "url": dataUrl,
        "type": type,
        //"data": data,
        "data": function (d) {
          Object.assign(d, data);
          return d;
        },
        'dataSrc': function (response) {
          if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
            if (response.ReturnStatus.SuccessMessage && response.ReturnStatus.SuccessMessage.length > 0) {
              Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
            } else if (response.ReturnStatus.WarningMessage && response.ReturnStatus.WarningMessage.length > 0) {
              Functions.gritter(response.ReturnStatus.WarningMessage, 'warning');
            }
            // Verificar necessidade de converter object generico key, value em { key: value }
            if (isObjectList) {
              let transformedList = [];
              //para cada registo
              for (let rowIndex = 0; rowIndex < response.ReturnStatus.ReturnObject.aaData.length; rowIndex++) {
                const row = response.ReturnStatus.ReturnObject.aaData[rowIndex];
                let transformedRow = {};
                // para cada coluna
                for (let colIndex = 0; colIndex < row.length; colIndex++) {
                  const col = row[colIndex];
                  transformedRow[col.Key] = col.Value;
                }
                transformedList.push(transformedRow);
              }
              return transformedList;
            } else {
              return response.ReturnStatus.ReturnObject.aaData;
            }
          } else if (!response.IsAuthenticated) {
            document.getElementById('needLogin').click();
          } else {
            Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger');
          }
          return [];
        },
        'dataFilter': function (data) {
          var response = jQuery.parseJSON(data);
          if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject) {
            response.recordsTotal = response.ReturnStatus.ReturnObject.iTotalRecords;
            response.recordsFiltered = response.ReturnStatus.ReturnObject.iTotalDisplayRecords;

            if (isObjectList) {
              let transformedList = [];
              //para cada registo
              for (let rowIndex = 0; rowIndex < response.ReturnStatus.ReturnObject.aaData.length; rowIndex++) {
                const row = response.ReturnStatus.ReturnObject.aaData[rowIndex];
                let transformedRow = {};
                // para cada coluna
                for (let colIndex = 0; colIndex < row.length; colIndex++) {
                  const col = row[colIndex];
                  transformedRow[col.Key] = col.Value;
                }
                transformedList.push(transformedRow);
              }
              response.data = transformedList;
            } else {
              response.data = response.ReturnStatus.ReturnObject.aaData;
            }
          } else {
            response.recordsTotal = 0;
            response.recordsFiltered = 0;
          }

          return JSON.stringify(response); // return JSON string
        },
        "error": function (xhr, textStatus, error) {
          var response = tryParseJSON(xhr.responseText);

          // if (JSON.parse(xhr.responseText)['IsAuthenticated'] === false) {
          if (response && response['IsAuthenticated'] === false) {
            document.getElementById('needLogin').click();
          } else if (textStatus === 'timeout') {
            $.gritter.add({
              title: translates.serverTimeout,
              class_name: "danger"
            });
          }
          else {
            $.gritter.add({
              title: translates.serverError,
              class_name: "danger"
            });
          }

          $('.dataTables_processing').hide();
          Functions.loading(false);
        }
      },
      "language": { "url": langFile },
      "orderCellsTop": true,
      "scrollX": true,
      "autoWidth": false,
      "lengthMenu": (typeof parameters !== 'undefined' && parameters.lengthMenu ) ? parameters.lengthMenu : [[10, 25, 50, -1], [10, 25, 50, translates.all]],
      "scrollCollapse": true,
      "aoColumns": aoColumns,
      "columnDefs": columnDefs,
      "paging": paging,
      'pagingType': 'simple_numbers',
      'select': select,
      preDrawCallback: function (settings) {
        Functions.loading(true);
      },
      drawCallback: function (settings) {
        // FUNCOES DAS ACOES
        // quando clica para abrir a dropdown
        $('.dropdownSolo').on('show.bs.dropdown', function (event) {
          var button = $(event.target).find('.dropdown-toggle').eq(0);
          if (!button || button.length == 0) {
            return;
          }
          var dropDownTop = button.offset().top + button.outerHeight() - $(window).scrollTop();
          var dropDownEl = $(event.target).find('.dropdown-menu');
          dropDownEl.css('top', dropDownTop + "px");
          if (event.target.querySelector('.dropdown-menu-right')) { // se o menu é aberto para a direita
            var w = dropDownEl.width();
            var l = button.offset().left - w; // vai ver qual a distancia à esquerda e retira o comprimento do botao
            dropDownEl.css('left', l + "px");
            dropDownEl.css('right', "auto");
            if (dropDownEl.length > 1) { // se tiver algum submenu
              for (var index = 1; index < dropDownEl.length; index++) {
                var element = dropDownEl[index];
                // var l_ = screen.width-l; // a direita do submenu vai ser o comprimento do ecra menos a esquerda do menu
                // var l_ = window.innerWidth-l; // a direita do submenu vai ser o comprimento do ecra menos a esquerda do menu
                var l_ = document.querySelector('body').getBoundingClientRect().right - l; // a direita do submenu vai ser o comprimento do ecra menos a esquerda do menu
                $(element).css('right', l_ + "px");
                $(element).css('left', "auto");
                $(element.parentElement).hover(function (event) { // quando esta a abrir o submenu
                  // var dropDownTop_ = $(this).offset().top; // o submenu vai ter o top na mesma posicao que o menu que o abre
                  var dropDownTop_ = $(this).offset().top + document.querySelector('body').getBoundingClientRect().top; // o submenu vai ter o top na mesma posicao que o menu que o abre
                  $(this).find('ul').eq(0).css('top', dropDownTop_)
                });
              }
            }
          } else {
            dropDownEl.css('left', button.offset().left + "px");
            if (dropDownEl.length > 1) { // se tiver algum submenu
              for (var index = 1; index < dropDownEl.length; index++) {
                var element = dropDownEl[index];
                var l_ = button.offset().left + button.width() + $(element.parentElement).width(); // o submenu vai comecar com a esquerda do botao mais o comprimento do botao mais o comprimento do menu
                $(element).css('left', l_ + "px");
                $(element.parentElement).hover(function (event) { // quando esta a abrir o submenu
                  var dropDownTop_ = $(this).offset().top; // o submenu vai ter o top na mesma posicao que o menu que o abre
                  $(this).find('ul').eq(0).css('top', dropDownTop_)
                });
              }
            }
          }
        });
        // quando faz o scroll tem de se remover porque se nao fica fora do sitio
        $(document).scroll(function () {
          $('.dropdownSolo').removeClass('open');
        });

        // botão exportar
        $('.table-export').click(function () {
          var id = table.row($(event.target).closest('tr')).data()['ID'];
          $('#btn-export').attr('data-id', id);
          $('#btn-export').click();
        });

        Functions.loading(false);
      },
      initComplete: function () {
        if (hasFilterButtons && document.querySelectorAll(idTabela + ' .findInput').length > 0) {
          var input = $(this.selector + '_filter input').unbind(),
            self = this,
            $searchButton = $('<button type="button" class="btn btn-sm btn-primary no-margin-bottom">')
              .text(translates.search)
              .click(function () {
                self.search(input.val()).draw();
              }),
            $clearButton = $('<button type="button" class="btn btn-sm btn-default no-margin-bottom">')
              .text(translates.clear)
              .click(function () {
                input.val('');
                $searchButton.click();
              });
          $(this.selector + '_filter').append($searchButton, $clearButton);
        }
      }
    });

    // keepPage - true/false se mantém a página ao refrescar a tabela
    table.reloadTableParameters = function (newData, keepPage) {
      data = newData;
      if (keepPage) {
        table.ajax.reload(null, false);
      } else {
        table.ajax.reload();
      }
    };

    var stored = JSON.parse(localStorage.getItem('DataTables_' + idTabela.substring(1, idTabela.length) + '_' + window.location.pathname));

    //#region FILTRO-COLUNA
    var index = 0, element = idTabela + ' thead tr:eq(1) th:not(:last-child)'; //ir buscar a segunda linha da tabela
    element = idTabela + ' thead tr:eq(1) th:eq(';

    table.columns().every(function (i) {
      var self = this;
      if (self.header().getAttribute('rowspan') == null) { // saber se nao e uma uniao de linhas
        $(element + index + ') input, ' + element + index + ') select, ' + element + index + ') mat-select').on('keyup change dp.change', function (ev) { //ao premir uma tecla vai fazer a pesquisa
          var regex = (ev.target.nodeName == 'SELECT') || (ev.target.nodeName == 'MAT-SELECT');
          if (self.search() !== this.value) {
            self.search(this.value, !regex);
            // para pesquisar logo quando altera o valor do select necessario fazer o draw
            // self.draw();
          }
        });
        $(element + index + ') input').on('keypress', function (e) { //ao premir ENTER vai fazer a pesquisa
          if (e.which == 13) {
            var regex = false;
            self.search(this.value, !regex).draw();
          }
        });

        if (typeof parameters === 'undefined') {
          // verificar se o localstorage tem algum valor de procura na coluna
          if (stateSave && stored && stored.hasOwnProperty('columns')) {
            var value = stored && stored.hasOwnProperty('columns') && stored.columns[i] ? stored.columns[i].search.search : "";
            var InputSelectElement = $(element + index + ') input, ' + element + index + ') mat-select');
            if (InputSelectElement.length > 0) {
              InputSelectElement.val(value);
              // forcar o evento change para colocar os valores nos filtros do angular
              if ("createEvent" in document) {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                InputSelectElement[0].dispatchEvent(evt);
              }
              else {
                InputSelectElement[0].fireEvent("onchange");
              }
            }
          }
        } else {
          // verificar se os filtros têm algum valor de procura na coluna
          if (parameters && parameters.columns && parameters.columns[i]) {
            var value = parameters.columns[i];
            var InputSelectElement = $(element + index + ') input, ' + element + index + ') mat-select');
            if (InputSelectElement.length > 0) {
              InputSelectElement.val(value);
              // forcar o evento change para colocar os valores nos filtros do angular
              if ("createEvent" in document) {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                InputSelectElement[0].dispatchEvent(evt);
              }
              else {
                InputSelectElement[0].fireEvent("onchange");
              }
            }
          }
        }

        index++;
      }
    });

    // adicionar botões de filtro para os filtro-coluna na última coluna
    if (hasFilterButtons && document.querySelectorAll(idTabela + ' .findInput').length > 0) {
      //var $searchButton = $('<button type="button" id="searchButton" class="btn btn-sm btn-primary no-margin-bottom">')
      var $searchButton = $('<button type="button" class="btn btn-sm btn-primary no-margin-bottom searchButton">')
        .text(translates.search)
        .click(function () {
          table.draw();
        }),
        //$clearButton = $('<button type="button" id="clearButton" class="btn btn-sm btn-default no-margin-bottom">')
        $clearButton = $('<button type="button" class="btn btn-sm btn-default no-margin-bottom clearButton">')
          .text(translates.clear)
          .click(function () {
            var inputs = $(idTabela + '_wrapper .findInput');
            for (var i = 0; i < inputs.length; i++) {
              $(inputs[i]).val('');
              var event;
              if (inputs[i].tagName === 'MAT-SELECT') {
                // inputs[i].dispatchEvent(new Event('change'));
                event = document.createEvent('Event');
                event.initEvent('selectionChange', false, true);
                inputs[i].dispatchEvent(event);
              } else {
                // inputs[i].dispatchEvent(new Event('input')); // Use for Chrome/Firefox/Edge
                event = document.createEvent('Event');
                event.initEvent('input', false, true);
                inputs[i].dispatchEvent(event);

                // inputs[i].dispatchEvent(new Event('change')); // Use for Chrome/Firefox/Edge + IE11
                event = document.createEvent('Event');
                event.initEvent('change', false, true);
                inputs[i].dispatchEvent(event);
              }
            }
            table.columns().every(function () {
              this.search('');
            });
            $searchButton.click();
          });
      $(idTabela + ' thead tr:eq(1) th:last-child').append($searchButton, $clearButton); // alterar a última coluna da segunda linha da tabela
    }
    //#endregion

    return table;
  },
  datatableDestroy: function(idTabela){
    $(idTabela).DataTable().destroy();
  },
  datatablesWithDataSet: function (idTabela, dataSet, translates, aoColumns, columnDefs, langFile, orderFieldIndex, paging,
    globalSearch, adaptativeDOM, select, orderType, parameters) {
    // adaptativeDOM = true quando se usa em modals (porque é mais pequena)
    orderFieldIndex = (typeof orderFieldIndex === 'undefined') ? 1 : orderFieldIndex;
    paging = (typeof paging === 'undefined') ? true : paging;
    globalSearch = (typeof globalSearch === 'undefined') ? true : globalSearch;
    select = (typeof select === 'undefined') ? false : select;
    orderType = (typeof orderType === 'undefined') ? 'asc' : orderType;
    //var domInit = globalSearch ? "lftipr" : 't <"col-sm-4 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-4 no-padding margin-top-10"p>';
    if (adaptativeDOM === true) {
      var domInit = globalSearch ? '<"col-sm-4 margin-top-10"f> t <"col-sm-4 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-8 no-padding margin-top-10"p>'
        : 'tr <"col-sm-4 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-8 no-padding margin-top-10"p>';
    } else {
      var domInit = globalSearch ? "lftipr" : 't <"col-sm-3 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-5 no-padding margin-top-10"p>';
    }
    //'l' - Length changing ; 'f' - Filtering input; 't' - The table; 'i' - Information; 'p' - Pagination; 'r' - pRocessing

    // adicionar a funcao processing para conseguir fazer o trigger e colocar a informação que esta a processar
    jQuery.fn.dataTable.Api.register('processing()', function (show) {
      return this.iterator('table', function (ctx) {
        ctx.oApi._fnProcessingDisplay(ctx, show);
      });
    });

    var tabela = $(idTabela).DataTable({
      processing: true,
      "dom": domInit,
      "data": dataSet,
      "language": { "url": langFile },
      "orderCellsTop": true,
      "scrollX": true,
      "autoWidth": false,
      "order": [[orderFieldIndex, orderType]],
      'rowsGroup': (typeof parameters !== 'undefined') ? parameters.rowsGroup : undefined,
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, translates.all]],
      "scrollCollapse": true,
      "aoColumns": aoColumns,
      "aoColumnDefs": columnDefs,
      "paging": paging,
      'pagingType': 'simple_numbers',
      'select': select,
      preDrawCallback: function (settings) {
        Functions.loading(true);
      },
      drawCallback: function (settings) {
        // FUNCOES DAS ACOES
        // quando clica para abrir a dropdown
        $('.dropdownSolo').on('show.bs.dropdown', function (event) {
          var button = $(event.target).find('.dropdown-toggle').eq(0);
          if (!button || button.length == 0) {
            return;
          }
          var dropDownTop = button.offset().top + button.outerHeight() - $(window).scrollTop();
          var dropDownEl = $(event.target).find('.dropdown-menu');
          dropDownEl.css('top', dropDownTop + "px");
          if (event.target.querySelector('.dropdown-menu-right')) { // se o menu é aberto para a direita
            var w = dropDownEl.width();
            var l = button.offset().left - w; // vai ver qual a distancia à esquerda e retira o comprimento do botao
            dropDownEl.css('left', l + "px");
            dropDownEl.css('right', "auto");
            if (dropDownEl.length > 1) { // se tiver algum submenu
              for (var index = 1; index < dropDownEl.length; index++) {
                var element = dropDownEl[index];
                // var l_ = screen.width-l; // a direita do submenu vai ser o comprimento do ecra menos a esquerda do menu
                // var l_ = window.innerWidth-l; // a direita do submenu vai ser o comprimento do ecra menos a esquerda do menu
                var l_ = document.querySelector('body').getBoundingClientRect().right - l; // a direita do submenu vai ser o comprimento do ecra menos a esquerda do menu
                $(element).css('right', l_ + "px");
                $(element).css('left', "auto");
                $(element.parentElement).hover(function (event) { // quando esta a abrir o submenu
                  // var dropDownTop_ = $(this).offset().top; // o submenu vai ter o top na mesma posicao que o menu que o abre
                  var dropDownTop_ = $(this).offset().top + document.querySelector('body').getBoundingClientRect().top; // o submenu vai ter o top na mesma posicao que o menu que o abre
                  $(this).find('ul').eq(0).css('top', dropDownTop_)
                });
              }
            }
          } else {
            dropDownEl.css('left', button.offset().left + "px");
            if (dropDownEl.length > 1) { // se tiver algum submenu
              for (var index = 1; index < dropDownEl.length; index++) {
                var element = dropDownEl[index];
                var l_ = button.offset().left + button.width() + $(element.parentElement).width(); // o submenu vai comecar com a esquerda do botao mais o comprimento do botao mais o comprimento do menu
                $(element).css('left', l_ + "px");
                $(element.parentElement).hover(function (event) { // quando esta a abrir o submenu
                  var dropDownTop_ = $(this).offset().top; // o submenu vai ter o top na mesma posicao que o menu que o abre
                  $(this).find('ul').eq(0).css('top', dropDownTop_)
                });
              }
            }
          }
        });
        // quando faz o scroll tem de se remover porque se nao fica fora do sitio
        $(document).scroll(function () {
          $('.dropdownSolo').removeClass('open');
        });

        Functions.loading(false);
      }
    });

    reloadTableDataSetParameters = function (newData) {
      tabela.clear().rows.add(newData).draw();
    };
    //#region FILTRO-COLUNA
    var index = 0, element = idTabela + ' thead tr:eq(1) th:not(:last-child)'; //ir buscar a segunda linha da tabela,

    element = idTabela + ' thead tr:eq(1) th:eq(';
    tabela.columns().every(function () {
      var self = this;
      if (self.header().getAttribute('rowspan') == null) {
        $(element + index + ') input, ' + element + index + ') select, ' + element + index + ') mat-select').on('keyup change dp.change', function (ev) { //ao premir uma tecla vai fazer a pesquisa
          var regex = (ev.target.nodeName == 'SELECT') || (ev.target.nodeName == 'MAT-SELECT');
          if (self.search() !== this.value)
            self.search(this.value, !regex);
        });
        $(element + index + ') input').on('keypress', function (e) { //ao premir ENTER vai fazer a pesquisa
          if (e.which == 13) {
            var regex = false;
            self.search(this.value, !regex);
            var dados = self.rows().data();
            self.clear().rows.add(dados).draw();
          }
        });
        index++;
      }
    });

    // adicionar botões de filtro para os filtro-coluna na última coluna
    if (document.querySelectorAll(idTabela + ' .findInput').length > 0) {
      //var $searchButton = $('<button id="searchButton" type="button" class="btn btn-sm btn-primary no-margin-bottom">')
      var $searchButton = $('<button type="button" class="btn btn-sm btn-primary no-margin-bottom searchButton">')
        .text(translates.search)
        .click(function () {
          var dados = tabela.rows().data();
          tabela.clear().rows.add(dados).draw();
        }),

        //$clearButton = $('<button id="clearButton" type="button" class="btn btn-sm btn-default no-margin-bottom">')
        $clearButton = $('<button type="button" class="btn btn-sm btn-default no-margin-bottom clearButton">')
          .text(translates.clear)
          .click(function () {
            var inputs = $(idTabela + '_wrapper .findInput');
            for (var i = 0; i < inputs.length; i++) {
              $(inputs[i]).val('');
              if (inputs[i].tagName === 'MAT-SELECT') {
                // inputs[i].dispatchEvent(new Event('change'));
                var event = document.createEvent('Event');
                event.initEvent('selectionChange', false, true);
                inputs[i].dispatchEvent(event);
              }
            }

            tabela.columns().every(function () {
              this.search('');
            });
            $searchButton.click();
          });
      $(idTabela + ' thead tr:eq(1) th:last-child').append($searchButton, $clearButton); // alterar a última coluna da segunda linha da tabela
    }
    ////#endregion

    var columnToEdit;
    $(idTabela + ' tbody').on('click', '.editable-column', function () {
      var row = this.parentElement;
      if (!$(idTabela).hasClass("editing")) {
        $(idTabela).addClass("editing");
        var data = tabela.row(row).data();
        var $row = $(row);
        columnToEdit = aoColumns[tabela.cell($(this)).index().column].data;
        var thisPosition = $(this);
        var thisPositionText = thisPosition.text();
        thisPosition.empty().append($('<input />', {
          'type': 'number',
          'id': 'Position_' + data[0],
          'value': data[0],
          'class': 'full-width changePosition'
        }));
        $('#Position_' + data[0]).val(thisPositionText);
        document.getElementById('Position_' + data[0]).focus();
      }
    });
    $(idTabela + ' tbody').on('blur', '.changePosition', function () {
      var $this = $(this);

      var tempData = tabela.row($this.closest('tr')).data();
      tempData[columnToEdit] = $this.val();
      columnToEdit = null;
      tabela.row($this.closest('tr')).data(tempData);
      $this.parent('td').empty().text($this.val());
      $(idTabela).removeClass('editing');

      document.getElementById(idTabela + '-edit').click();
    });
    return tabela;
  },
  datatablesSimple: function (idTabela, translates, columnDefs, langFile, orderFieldIndex, paging, globalSearch) {
    paging = (typeof paging === 'undefined') ? true : paging;
    globalSearch = (typeof globalSearch === 'undefined') ? true : globalSearch;

    var domInit = globalSearch ? 'f t <"col-sm-4 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-4 no-padding margin-top-10"p>'
      : 't <"col-sm-4 no-padding margin-top-10"l> <"col-sm-4 no-padding margin-top-10 text-center"i> <"col-sm-4 no-padding margin-top-10"p>';
    //'l' - Length changing ; 'f' - Filtering input; 't' - The table; 'i' - Information; 'p' - Pagination; 'r' - pRocessing

    var tabela = $(idTabela).DataTable({
      "dom": domInit,
      'stateSave': paging && globalSearch, //para guardar o estado, uses the HTML5 localStorage and sessionStorage
      "language": { "url": langFile },
      "orderCellsTop": true,
      "scrollX": true,
      "autoWidth": false,
      "order": [[orderFieldIndex, 'asc']],
      "lengthMenu": [[-1], [translates.all]],
      "scrollCollapse": true,
      "columnDefs": columnDefs,
      "paging": paging,
      'pagingType': 'simple_numbers',
      preDrawCallback: function (settings) {
        Functions.loading(true);
      },
      drawCallback: function (settings) {
        Functions.loading(false);
      }
    });

    return tabela;
  },
  datatables_reload: function (idTabela, dataSet, resetPage) {
    var table = $(idTabela).DataTable();

    if (dataSet === undefined || dataSet == null) 
    {
      if(!table.ajax.url()){
        table.clear().draw();
      }
      else{
        table.ajax.reload();
      }
    }
    else {
      if (typeof resetPage === 'undefined' || resetPage === true) { // Muda para a primeira página
        table.clear();
        table.rows.add(dataSet).draw();
      } else { // Mantém a página
        var info = table.page.info();

        if (info.page > 0) {
          // when we are in the second page or above
          if (info.recordsTotal - 1 > info.page * info.length) {
            table.clear();

            // after removing 1 from the total, there are still more elements
            // than the previous page capacity 
            table.rows.add(dataSet).draw(false);
          } else {
            table.clear();

            // there are less elements, so we navigate to the previous page
            table.rows.add(dataSet).draw(false);
          }
        } else {
          table.clear();
          table.rows.add(dataSet).draw(false);
        }
      }
    }
  },
  datatables_newDataSet: function (idTabela, dataSet) {
    $(idTabela).DataTable().clear();
    $(idTabela).DataTable().rows.add(dataSet);
  },
  datatableExists: function (idTable) {
    return $.fn.dataTable.isDataTable(idTable);
  },
  resizeAllDatatables: function () {
    var tables = $.fn.dataTable.tables({ visible: true, api: true });

    if (tables instanceof Array) {
      for (var i = 0; i < tables.length; i++) {
        try {
          tables[i].columns.adjust();
        } catch (e) { }
      }
    } else {
      try {
        tables.columns.adjust();
      } catch (e) { }
    }
  },
  autoResizeDatatables: function () {
    window.addEventListener('resize', function () {
      Functions.resizeAllDatatables();
    });
  },
  cleanLayout: function () {
    $('.dockmodal').remove();
    $.gritter.removeAll();
  },
  pageGallery: function () {
    //MagnificPopup for images zoom
    $('.image-zoom').magnificPopup({
      type: 'image',
      mainClass: 'mfp-with-zoom', // this class is for CSS animation below
      zoom: {
        enabled: true, // By default it's false, so don't forget to enable it

        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function

        // The "opener" function should return the element from which popup will be zoomed in
        // and to which popup will be scaled down
        // By defailt it looks for an image tag:
        opener: function (openerElement) {
          // openerElement is the element on which popup was initialized, in this case its <a> tag
          // you don't need to add "opener" option if this code matches your needs, it's defailt one.
          var parent = $(openerElement).parents("div.img");
          return parent;
        }
      }

    });
  },
  serialize: function (form) {
    // return $(form).serialize();
    return JSON.stringify(jQuery(form).serializeArray());
  },
  tooltipCSS: function () {

    $('.tooltip--right, .tooltip--triangle, .tooltip--bottom, .tooltip--left').on('mouseenter', function (event) {
      //ir buscar o span e a sua posicao(left e top)
      //colocar como absolute para ficar à frente de tudo e um id para depois conseguir identifica-lo

      var span = $(event.currentTarget);
      // verificar se tem o id (já está no sitio certo) ou já existe algum que esteja ainda a fazer o out (o ie/edge é muito lento)
      if (span.attr('id') === 'spanToRemoveToolTip' || $("#spanToRemoveToolTip").length > 0) {
        // existe ainda um span de tooltip que ainda nao foi para o sitio certo, por isso nao vai fazer nada
        event.stopPropagation();
        return;
      }

      if ('undefined' !== typeof span.offset()) {

        var spanPos = span.offset();
        // var widthTotal = span.innerWidth();
        var widthTotal = span.children().length > 0 ? $(span.children()[0]).innerWidth() + parseInt($(span.children()[0]).css('marginLeft')) : span.innerWidth();
        var top = spanPos.top;
        var aux = span.children().length > 0 ? 14 + parseInt($(span.children()[0]).css('marginLeft')) * 2 : 14;
        var left = spanPos.left + document.body.scrollLeft + (widthTotal - aux) / 2 + 0.2;

        var spanStack = span.find('.fa-stack:first');
        if (spanStack.length > 0) { // se for um stack de icons, o top e left são alterados
          spanPos = spanStack.offset();
          if (!spanStack.hasClass('letterSpan')) {
            top = spanPos.top;
          }

          aux = spanStack.hasClass('letterSpan') ? 16 : 14;
          left = spanPos.left + document.body.scrollLeft + (widthTotal - aux) / 2 - 0.8;
          span.find('.icon-stack').css('left', '0px');
        }

        // manter o width da coluna da tabela
        if (span.closest('td').length > 0) {
          span.closest('td').css('min-width', span.closest('td').width() + 'px');
        }

        // o parent vai ter de manter as mesmas dimensoes para os restantes icons nao sairem da posicao inicial
        // span.parent().css('min-width', span.parent().width() + 1 + 'px');
        var parentSpan = span.parent().parent().prop("tagName") == 'SPAN' ? span.parent().parent() : span.parent();
        parentSpan.css('min-width', widthTotal + 'px');
        parentSpan.css('float', 'left');

        if (!parentSpan.is(':first-child')) { // se nao for o primeiro icon
          var siblings = parentSpan.siblings();
          siblings.css('float', 'left');
          var lastSib = siblings[siblings.length];
          $(lastSib).css('float', 'none'); // nao colocar no ultimo porque se nao a table pode fazer scroll e destruir as posicoes
        }

        if (parseInt(parentSpan.css('min-height')) == 0) {
          parentSpan.css('min-height', span.parent().outerHeight(true) + 'px');
        }
        span.css({
          position: 'absolute',
          left: left,
          top: top,
          display: 'block',
          'z-index': 1000
        }).attr('id', 'spanToRemoveToolTip');

        //adicionar ao body
        $('body').append(span.detach());

        span.addClass('focus'); // no firefox nao estava a funcionar o :hover

      }
    });
    $('.tooltip--right, .tooltip--triangle, .tooltip--bottom, .tooltip--left').on('mouseleave', function (event) {
      //quando o rato sai
      var target = $(event.currentTarget), targetStack = target.find('.icon-stack'),
        classe = target.attr('class');
      classe = classe.replace(' bigTooltip', '').replace(' focus', ''); // classe focus necessaria para o firefox que nao estava a fazer o :hover
      var span = $('#spanToRemoveToolTip');
      span.removeClass('focus');
      if (span.length) { // se houver o elemento
        if (span.attr('id') !== 'spanToRemoveToolTip') {
          event.stopPropagation();
          return;
        }

        if (targetStack.length > 0) {
          targetStack.css('left', '5px');
        }
        //descobrir qual esta sem o span e anexar-lhe
        var parentAlone = $('.tooltipAbs:not(:has(.' + classe + '))');
        if (parentAlone.length == 1) {
          parentAlone.append(span.removeAttr("style").detach()).removeAttr("style");
        }
        $('.tooltipAbs').css("float", "none");
        //remover o id
        span.removeAttr('id');
      }
    });
  },
  /**
   * @param  {string} title - traducao do titulo a dar à janela
   * @param  {array} translatesSource - array de strings com traducoes necessarias
   * @param  {string} idTabela - id da tabela a onde a datatable vai ser criada
   * @param  {(string|null)} dataUrl - se for para ir buscar os dados ao servidor- url do controlador, caso contrario null
   * @param  {(array|null)} dataDataset - se for para usar array de dados - array de dados, caso contrario (dados estao no servidor) null
   * @param  {string} urlAlias - url base do angular para conseguir aceder aos assets e ir buscar o ficheiro da datatables de traducao
 * @param  {boolean | null } globalSearchAp - se vai colocar a procura global ou nao
   * @param  {string} translateLang - lingua atualmente utilizada (currentLanguage)
   * @param  {(string|null)} type - se for para ir buscar os dados ao servidor- 'post' ou 'get', caso contrario null
   * @param  {any} data - se for para ir buscar os dados ao servidor- dados que possam existir para filtrar
   * @param  {array} aoColumns - colunas a usar no datatables
   * @param  {array} columnDefs - definicoes das colunas do datatables
   * @param  {(number|null)} maxLinhasSelecionadas - numero máximo de linhas que pode selecionar, se null nao tem limite
   * @param  {any} funcaoArrastar - funcao do angular para colocar no drop da linha
   * @param  {any} component
   * @returns {Promise} tabela
   */
  dockmodalN: function (title, translatesSource, idTabela, dataUrl, dataDataset, urlAlias, globalSearchAp, translateLang, type, data, aoColumns, columnDefs, maxLinhasSelecionadas, idDropZone, funcaoArrastar, component) {
    var translates = {
      clear: translatesSource['BUTTON.CLEAR'],
      update: translatesSource['REFRESH'],
      all: translatesSource['BUTTON.ALL'],
      serverTimeout: translatesSource['SERVER_TIMEOUT'],
      serverError: translatesSource['SERVER_ERROR'],
      search: translatesSource['BUTTON.SEARCH'],
      none: translatesSource['NONE'],
      add: translatesSource['ADD'],
    };

    $('.all-dockmodal').dockmodal('minimize');
    var disableAll = maxLinhasSelecionadas && maxLinhasSelecionadas < 2 ? ' disabled' : '';

    $(idTabela + '-container').dockmodal({
      initialState: 'docked',
      title: title,
      showClose: true,
      showPopout: false,
      showMinimize: true,
      height: '90%',
      buttons: [
        {
          html: translates.none,
          buttonClass: 'btn removeHref pull-left',
          click: function (e, dialog) {
            $(idTabela).DataTable().rows('').deselect();
            return false;
          }
        }, {
          html: translates.all,
          buttonClass: 'btn removeHref pull-left' + disableAll,
          click: function (e, dialog) {
            $(idTabela).DataTable().rows('').select();
            return false;
          }
        }, {
          html: translates.add,
          buttonClass: 'btn btn-primary removeHref add' + idTabela,
          click: function (e, dialog) {
            // acoes estao definidas no typescript
            return false;
          }
        }
      ],
      close: function (event, dialog) {
        $(idTabela).DataTable().destroy();
        $(idTabela).empty();
        $(idTabela).parent()[0].removeChild($(idTabela)[0]);
        $(idTabela + '-container').parent()[0].removeChild($(idTabela + '-container')[0]);
      },
      create: function (event, dialog) {
        // // quando é criado passa para a frente dos outros
        // var primeiroDock = document.getElementsByClassName('dockmodal');
        // if (primeiroDock.length > 0) {
        //     primeiroDock = primeiroDock[0];
        //     var esteDock = event[0].parentNode.parentNode;
        //     if(primeiroDock != esteDock){
        //         setTimeout(() => { // tem de ter um compasso de espera porque ainda nao esta criado
        //             primeiroDock.parentNode.insertBefore(esteDock, primeiroDock); // vai inserir em primeiro lugar
        //             $(event[0]).dockmodal('refreshLayout'); // fazer refresh das posicoes (refreshes the element position and recalculates)
        //         }, 100);
        //     }
        // }
      }
    });

    $('.removeHref').removeAttr('href');

    if (!$.fn.DataTable.isDataTable(idTabela)) {
      // verificar se vai buscar dados ao servidor ou usa dataSet
      var dataSet = dataUrl ? false : true;
      var dados = dataUrl ? dataUrl : dataDataset;
      var globalSearch = (globalSearchAp != null) ? globalSearchAp : true;
      return Functions.datatablesDockmodal(dataSet, idTabela, dados, globalSearch, translates, aoColumns, columnDefs, (urlAlias + 'assets/resources/datatables-' + translateLang + '-small.json'), type, data, 1, 'asc', maxLinhasSelecionadas, idDropZone, funcaoArrastar, component);
    } else {
      return new Promise(function (resolve, reject) {
        resolve(null);
      });
    }
  },
  minimizeMaxDockmodal: function (idTabela, soMax) {
    if ($(idTabela + '-container').length > 0) {
      var botao = $(idTabela + '-container')[0].parentNode.parentNode.children[0].children[1];
      if (soMax) { // para maximizar
        $(idTabela + '-container').dockmodal("restore");
      } else if (soMax === false) { // para minimizar
        $(idTabela + '-container').dockmodal("minimize");
      } else {
        botao.click(); // se estiver minimizado vai maximizar e vice-versa
      }
      if (botao.getAttribute('title').toLowerCase() === 'minimize') {
        // esta maximizado vai passar para a direita
        var ultimoDock = document.getElementsByClassName('dockmodal'); // vai aos docks todos
        if (ultimoDock.length > 0) {
          ultimoDock = ultimoDock[ultimoDock.length - 1]; // vai buscar o ultimo dock
          var esteDock = $(idTabela + '-container')[0].parentNode.parentNode;
          if (ultimoDock != esteDock) { // verifica se este é não o ultimo dock
            ultimoDock.parentNode.insertBefore(esteDock, ultimoDock.nextSibling); // vai colocar depois do ultimo
            $(idTabela + '-container').dockmodal('refreshLayout'); // fazer refresh das posicoes (refreshes the element position and recalculates)
          }
        }
      }
    }
  },
  closeDockmodal: function (idTabela) {
    $(idTabela + '-container').dockmodal("close");
  },
  /**
   * @param  {boolean} dataSet - se a origem dos dados é um dataset (true), ou se vai buscar ao servidor (false)
   * @param  {string} idTabela - id da tabela a onde vai criar a datatables
   * @param  {any} dataUrlDataSet - se for dataSet é o array de dados, caso contrario é o url do servidor
   * @param  {boolean} globalSearch - para saber se vai levar o filtro da procura global
   * @param  {any} translates - object de strings com traducoes
   * @param  {array} aoColumns - colunas a utilizar
   * @param  {array} columnDefs - definicoes das colunas
   * @param  {string} langFile - url do ficheiro de traducoes do datatables
   * @param  {string} type - se nao for dataSet string com POST ou GET
   * @param  {any} data - se nao for dataSet dados a enviar ao servidor alem dos parametros da datatable (por exemplo filtros)
   * @param  {number} orderIndex - index da coluna pela qual se pretende ordenar
   * @param  {string} orderDirection - se a ordenacao é 'asc' ou 'desc'
   * @param  {(number|null)} maxLinhasSelecionadas - numero máximo de linhas que pode selecionar, se null nao tem limite
   * @param  {any} funcaoArrastar - funcao do angular a executar quando faz o drop da linha
   * @param  {any} component - componente
   * @returns {Promise} array com [tabela, funcao de reload]
   */
  datatablesDockmodal: function (dataSet, idTabela, dataUrlDataSet, globalSearch, translates, aoColumns, columnDefs, langFile, type, data, orderIndex, orderDirection, maxLinhasSelecionadas, idDropZone, funcaoArrastar, component) {

    return new Promise(function (resolve, reject) {
      $.getJSON(langFile, function (oLanguage) {
        // alterar a language para colocar a paginacao com os simbolos '<' e '>'
        oLanguage.oPaginate.sNext = '&gt';
        oLanguage.oPaginate.sLast = '&raquo';
        oLanguage.oPaginate.sFirst = '&laquo';
        oLanguage.oPaginate.sPrevious = '&lt';
        // objeto com definicoes para a datatable
        var objDatatable = {
          "dom": globalSearch ? 'fr<"col-xs-12 no-padding margin-bottom-neg-8"<"pull-left"l>p>t<"col-xs-12 no-padding"i>' : 'r<"col-xs-12 no-padding margin-bottom-neg-8"<"pull-left"l>p>t<"col-xs-12 no-padding"i>',
          //"dom": 'fr<"col-xs-12 no-padding margin-bottom-neg-8"<"pull-left"l>p>t<"col-xs-12 no-padding"i>',
          //'l' - Length changing ; 'f' - Filtering input; 't' - The table; 'i' - Information; 'p' - Pagination; 'r' - pRocessing
          select: {
            style: maxLinhasSelecionadas && maxLinhasSelecionadas < 2 ? 'single' : 'multi'
          },
          stateSave: false, //para guardar o estado, uses the HTML5 localStorage and sessionStorage
          "language": oLanguage,
          "orderCellsTop": true,
          "scrollX": true,
          "autoWidth": false,
          "order": [[orderIndex, orderDirection]],
          "lengthMenu": [[50, 100, 200], [50, 100, 200]],
          //"lengthMenu": [[-1], [translates.all]],
          "scrollCollapse": true,
          "aoColumns": aoColumns,
          "columnDefs": columnDefs,
          'pagingType': 'simple',
          "paging": true,
          preDrawCallback: function (settings) {
            Functions.loading(true);
          },
          drawCallback: function () {
            var self = this.api();
            // colocar a procurar no botao de lupa dos filtros
            if ($(idTabela + 'btn_search')) {
              $(idTabela + 'btn_search').click(function () {
                $(idTabela + 'btn_search').off();
                self.ajax.reload();
              })
            }
            if (self.data().any()) { // se a tabela tiver valores
              // verificar se ainda pode selecionar mais linhas
              $(idTabela + ' tbody').on('click', 'tr', function (ev) { // quando seleciona uma linha
                var rowsSelect = self.rows({ selected: true }), // linhas selecionadas
                  rowsSelectCount = rowsSelect.count(), // quantas linhas estao selecionadas
                  indexAtual = self.row(this).index(); // index da linha atual
                if (maxLinhasSelecionadas && rowsSelectCount == maxLinhasSelecionadas) { // ja atingiu o maximo das selecoes
                  // verificar se a linha clicada esta selecionada, retira a seleccao
                  var indexSelect = rowsSelect.indexes(); // ver quais os indexes selecionados
                  if (maxLinhasSelecionadas == 1) {
                    // se o limite for só 1, vai deselecionar a antiga e selecionar a nova
                    rowsSelect.deselect();
                  } else {
                    for (var i = 0; i < rowsSelectCount; i++) { // correr os indexes selecionados e verificar se tem o index atual
                      if (indexSelect[i] == indexAtual) {
                        self.row(this).deselect(); // vai retirar a seleccao
                      }
                    }
                    // nao vai fazer mais nada
                    return false;
                  }
                }
              });

              if (funcaoArrastar != null && document.querySelectorAll(idDropZone)) { // se a funcaoArrastar nao existe nao coloca o dragAndDrop
                // Obter todas as rows e coloca-las como draggable
                $(idTabela + ' tbody tr').draggable({
                  revert: 'valid',
                  appendTo: 'body',
                  // stack: "div", // nao pode ter isto porque vai mexer no zIndex de todos os elementos da pagina e depois nao volta a colocar corretamente
                  cursor: "move",
                  containment: "window",
                  scroll: true,
                  opacity: 0.7,
                  helper: 'clone',
                  start: function (event, ui) {
                    // colocar formatacao no elemento que esta a ser arrastado e no sitio onde é para depositar
                    var elementDrag = (document.getElementsByClassName('ui-draggable-dragging')[0]),
                      elementDrop = document.querySelectorAll(idDropZone)[0];
                    // verificar se tem o elemento Drop
                    if (!elementDrop) {
                      event.preventDefault();
                      return;
                    } else {
                      elementDrag.style.border = '1px dashed #000';
                      // elementDrag.style.zIndex = this.style.zIndex;
                      elementDrag.style.zIndex = '350';
                      elementDrag.style.width = this.offsetWidth + 'px';
                      elementDrop.style.border = '1px dashed pink';
                      elementDrop.style.backgroundColor = '#e3f1fd';

                      this.className += ' dragging' + idTabela.replace('#', '');
                    }
                  },
                  stop: function () {
                    var elementDrop = document.querySelectorAll(idDropZone)[0];
                    if (elementDrop) {
                      // retirar a formatação do elemento onde ia ser depositado
                      elementDrop.style.border = '';
                      elementDrop.style.backgroundColor = '';
                      this.className = this.className.replace(' dragging' + idTabela.replace('#', ''), '');
                    }
                  }
                });
                $(idDropZone).droppable({
                  accept: ".dragging" + idTabela.replace('#', ''), // elementos que aceita
                  drop: function (event, ui) {
                    var dragData = [];
                    // verificar se a linha arrastada faz parte das selecionadas
                    if ($.inArray(ui.draggable.index(), $(idTabela).DataTable().rows({ selected: true }).indexes()) == -1) {
                      // ir à datatable buscar a linha arrastada
                      dragData.push($(idTabela).DataTable().row(ui.draggable.index()).data());
                    }
                    var tableData = $(idTabela).DataTable().rows({ selected: true }).data();
                    for (var i = 0; i < tableData.length; i++) {
                      // so devolver os dados das linhas
                      dragData.push(tableData[i]);
                    }
                    // retirar a seleccao das linhas
                    $(idTabela).DataTable().rows('').deselect();
                    funcaoArrastar(dragData, component); // funcao do angular
                    return false;
                  }
                });
              }

            }

            // retirar a procura por keypress e colocar apenas com o enter
            var inputSeach = $(idTabela + '_filter input');
            inputSeach.unbind();
            inputSeach.bind('keyup', function (e) {
              if (e.keyCode == 13) {
                //var regex = aoColumns[self.index()].class && (aoColumns[self.index()].class).indexOf('exactValue') > -1;
                var regex = false;
                self.search(this.value, !regex).draw();
              };
            });

            Functions.loading(false);
          }
        };
        if (dataSet) {
          // se for dataset vai colocar no parametro data o array com os dados
          objDatatable.data = dataUrlDataSet;
        } else {
          // se nao for dataset vai colocar no parametro ajax os parametros para ir buscar ao servidor os dados
          objDatatable.processing = true;
          objDatatable.serverSide = true;
          objDatatable.ajax = {
            "url": dataUrlDataSet,
            "type": type,
            "data": function (d) {
              if (typeof data == 'function') {
                Object.assign(d, data());
              } else {
                Object.assign(d, data);
              }
              return d;
            },
            'dataSrc': function (response) {
              if (response.ReturnStatus.Successfull) {
                if (response.ReturnStatus.ReturnObject.aaData)
                  return response.ReturnStatus.ReturnObject.aaData;
                return response.ReturnStatus.ReturnObject.data;
              } else if (!response.IsAuthenticated) {
                document.getElementById('needLogin').click();
              } else {
                Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger');
              }
              return [];
            },
            'dataFilter': function (data) {
              var response = jQuery.parseJSON(data);
              if (!response.ReturnStatus.Successfull) {
                response.recordsTotal = 0;
                response.recordsFiltered = 0;
              } else if (response.ReturnStatus.ReturnObject) {
                if (response.ReturnStatus.ReturnObject.hasOwnProperty('iTotalRecords')) {
                  response.recordsTotal = response.ReturnStatus.ReturnObject.iTotalDisplayRecords;
                  response.recordsFiltered = response.ReturnStatus.ReturnObject.iTotalRecords;
                  response.data = response.ReturnStatus.ReturnObject.aaData;
                } else {
                  response.recordsTotal = response.ReturnStatus.ReturnObject.recordsTotal;
                  response.recordsFiltered = response.ReturnStatus.ReturnObject.recordsFiltered;
                  response.data = response.ReturnStatus.ReturnObject.data;
                }
              }
              return JSON.stringify(response); // return JSON string
            },
            // this sets up jQuery to give me errors
            "error": function (xhr, textStatus, error) {
              if (textStatus === 'timeout') {
                $.gritter.add({
                  title: translates.serverTimeout,
                  class_name: "danger"
                });
              }
              else {
                $.gritter.add({
                  title: translates.serverError,
                  class_name: "danger"
                });
              }
              $('.dataTables_processing').hide();
              Functions.loading(false);
            }
          };
        }
        var tabelaDock = $(idTabela).DataTable(objDatatable);

        var reloadTabelaDock = function (newData) {
          var oldData = data;
          var nData = function () {
            var d = {};
            if (typeof oldData == 'function') {
              Object.assign(d, oldData());
            } else {
              Object.assign(d, oldData);
            }
            $.extend(true, d, newData);
            return d;
          };
          data = nData;
          tabelaDock.ajax.reload();
        };

        // quando dá erro nao faz nada
        $.fn.dataTable.ext.errMode = 'none';
        resolve([tabelaDock, reloadTabelaDock]);
      });
    });

  },
  treeView: function () {
    $('label.tree-toggler').off('click');
    $('label.tree-toggler.with-children').on('click', function () {
      var icon = $(this).children('.fa');
      if (icon.hasClass('fa-caret-right')) {
        icon.removeClass('fa-caret-right').addClass('fa-caret-down');
      } else {
        icon.removeClass('fa-caret-down').addClass('fa-caret-right');
      }

      $(this).parent().children('ul.tree').toggle(300, function () {
        $(this).parent().toggleClass('open');
        $('.tree .nscroller').nanoScroller({ preventPageScrolling: true });
      });
    });
  },
  collapsible: function (className) {
    $(document).on('click', '.collapsible', function () {
      $(this).toggleClass('collapsed');
    });
  },
  obtainDateOfInputChange: function (element, evt) {
    var elementJQ = $(element);
    elementJQ.on('dp.hide', function (event) {
      var el = document.getElementById(element.replace('#', ''));
      if (el) {
        el.dispatchEvent(evt);
      }
    });
  }
};

function tryParseJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }

  return false;
};